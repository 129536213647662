import { Toast } from 'antd-mobile'
import axios from 'axios'

export const post: typeof axios.post = async (...args) => {
    try {
        const res = await axios.post(...args)
        if (res.status !== 200) {
            console.error(res)
            Toast.show({
                content: '网络异常!',
            })
            return null
        }
        if (res.data?.code !== 0) {
            console.error(res.data)
            Toast.show({
                content: `[${res.data.code}]: ${res.data.message || ''}`,
            })
            return null
        }
        return res as any
    } catch (exp) {
        console.error(exp)
        Toast.show({
            content: '网络异常!',
        })
    }
    return null
}

export const get: typeof axios.get = async (...args) => {
    try {
        const res = await axios.get(...args)
        if (res.status !== 200) {
            console.error(res)
            Toast.show({
                content: '网络异常!',
            })
            return null
        }
        if (res.data?.code !== 0) {
            console.error(res.data)
            Toast.show({
                content: `[${res.data.code}]: ${res.data.message || ''}`,
            })
            return null
        }
        return res as any
    } catch (exp) {
        console.error(exp)
        Toast.show({
            content: '网络异常!',
        })
    }
    return null
}