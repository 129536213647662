import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import { App } from './app'


import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

const convertStyle = () => {
  document.getElementById('root')?.style?.setProperty('height', `${window.innerHeight-5}px`)
}

window.addEventListener('resize', convertStyle)
window.addEventListener('DOMContentLoaded', convertStyle)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
