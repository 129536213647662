import { observer } from 'mobx-react-lite'
import { user } from '../stores/user'
import { Button, Input } from 'antd-mobile'
import './login.scss'

export const Login = observer(function () {
    return <section className='login'>
        <Input className='input' placeholder='请输入用户名' value={user.user} onChange={e => user.input(e)} />
        <Button className='button' block color='primary' size='large' onClick={() => user.login()}>
          登录
        </Button>
    </section>
})