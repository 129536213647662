import React from 'react'
import { observer } from 'mobx-react-lite'
import { user } from './stores/user'
import { Login } from './pages/login'
import { Chat } from './pages/chat'
import { SpinLoading } from 'antd-mobile'

export const App = observer(function () {
    React.useEffect(() => {
        user.init()
    }, [])
    if (!user.inited) {
        return <SpinLoading />
    }
    if (!user.token) {
        return <Login />
    }
    return <Chat />
})
