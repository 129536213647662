import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Input, List, ListRef, SpinLoading, SwipeAction } from 'antd-mobile'
import { Choice, chat } from '../stores/chat'

import './chat.scss'

export const Chat = observer(function () {
    React.useEffect(() => {
        chat.init()
    }, [])
    if (!chat.inited) {
        return <SpinLoading />
    }

    if (chat.current) {
        return <section className='chat'>
            <ChoiceList />
        </section>
    }

    return <section className='chat'>
        <SessionList />
    </section>
})

const SessionList = observer(function () {
    const values = Object.values(chat.sessions)
    return <List className='session-list' header={<SessionHeader />}>
        {values.map((session, i) => {
            return <SwipeAction
                key={i}
                rightActions={[
                    {
                        key: 'delete',
                        text: '删除',
                        color: 'danger',
                    },
                ]}
                onAction={(e) => {
                    chat.remove(session.sid)
                }}
            >
                <List.Item onClick={() => chat.to(session.sid)}>
                    {session.choices[0]?.content?.slice(0, 8) ?? '空会话'}
                </List.Item>
            </SwipeAction>
        })}
    </List>
})

const SessionHeader = observer(function () {
    return <div className='header'>
        <span>会话列表</span>
        <Button size='mini' color='success' fill='outline' onClick={() => chat.create()}>新会话</Button>
    </div>
})

const ChoiceList = observer(function () {
    function intoview (node: ListRef | null) {
        const el = node?.nativeElement as HTMLDivElement
        if (!el) {
            return
        }
        let last = el?.lastElementChild
        while (last?.lastElementChild) {
            last = last?.lastElementChild
        }
        if (last) {
            last.scrollIntoView(false)
        }
    }
    return <>
        <List className='choice-list' header={<ChoiceHeader />} ref={n => intoview(n)}>
            {chat.session.choices.map((choice, i) => <ChoiceItem choice={choice} key={i} />)}
        </List>
        <div className='action'>
            <Input placeholder='请输入聊天内容' value={chat.session.question} onChange={(e) => chat.input(e)} />
            <Button className='button' loading={chat.loading} size='mini' color='primary' onClick={() => chat.chat(chat.session.sid)}>发送</Button>
        </div>
    </>
})

const ChoiceHeader = observer(function () {
    const hander = chat.session.choices[0]?.content?.slice(0, 8) ?? '空会话'
    return <div className='header'>
        <Button size='mini' color='success' fill='outline' onClick={() => chat.back()}>返回</Button>
        <span>{hander}</span>
    </div>
})

const ChoiceItem = observer(function ({ choice }: { choice: Choice }) {
    const desc = choice.limit ? ` ${choice.index}/${choice.limit}` : ''

    if (choice.role === 'user') {
        return <List.Item>
            <div className={choice.role}>
                <label>我</label>
                <span>{choice.content}</span>
            </div>
        </List.Item>
    }
    return <List.Item>
        <div className={choice.role}>
            <label>GPT</label>
            <span>{choice.content}{desc}</span>
        </div>
    </List.Item>
})
