import { Toast } from 'antd-mobile'
import * as axios from '../utils/axios'
import { makeAutoObservable } from 'mobx'
import { user } from './user'

export type ChoiceRole = 'user' | 'system' | 'assistant'

export interface Choice {
    role: ChoiceRole
    content: string
    index?: number
    limit?: number
}

export interface Session {
    sid: string
    question: string
    choices: Choice[]
}

interface Assistant {
    content: string
    index: number
    limit: number
}

class Chat {

    public sessions: {
        [key: string]: Session
    } = {}

    public current: string

    public inited: boolean = false

    public loading: boolean = false

    constructor () {
        makeAutoObservable(this)
    }

    get session (): Session {
        return this.sessions[this.current]
    }

    async init (): Promise<void> {
        const res = await axios.get('/api/sessions', {
            headers: {
                token: user.token,
            },
        })
        if (!res) {
            Toast.show({
                content: '初始化异常!',
            })
            return
        }
        const sessions: {
            [key: string]: Session
        } = {}
        for (const item of res.data.data) {
            item.question = ''
            sessions[item.sid] = item
        }
        this.sessions = sessions
        this.inited = true
    }

    async create (): Promise<string | null> {
        const res = await axios.post('/api/create', {}, {
            headers: {
                token: user.token,
            },
        })
        if (!res) {
            Toast.show({
                content: '创建失败!',
            })
            return null
        }
        const sid: string = res.data.data
        const session = {
            sid,
            question: '',
            choices: [],
        }
        this.sessions[sid] = session
        this.current = sid
        return sid
    }

    input (question: string): void {
        if (!this.sessions[this.current]) {
            return
        }
        this.sessions[this.current].question = question
    }

    async remove (sid: string): Promise<void> {
        if (!this.sessions[sid]) {
            return
        }
        const res = await axios.post('/api/remove', {
            sid,
        }, {
            headers: {
                token: user.token,
            },
        })
        if (!res) {
            Toast.show({
                content: '删除失败!',
            })
            return
        }
        delete this.sessions[sid]
        if (this.current === sid) {
            this.current = ''
        }
    }

    async listChoices (sid: string): Promise<void> {
        const session = this.sessions[sid]
        if (!session) {
            Toast.show({
                content: '参数异常!',
            })
            return
        }
        const res = await axios.get(`/api/choices?sid=${session.sid}`, {
            headers: {
                token: user.token,
            },
        })
        if (!res) {
            Toast.show({
                content: '数据获取异常!',
            })
            return
        }
        const data: Choice[] = res.data.data
        session.choices = data
    }

    async to (sid: string): Promise<void> {
        if (!this.sessions[sid]) {
            return
        }
        await this.listChoices(sid)
        this.current = sid
    }

    back (): void {
        this.current = ''
    }

    async chat (sid: string): Promise<void> {
        const session = this.sessions[sid]
        if (!session || !session.question) {
            Toast.show({
                content: '参数异常!',
            })
            return
        }
        const question = session.question
        session.choices.push({
            role: 'user',
            content: question,
        })
        session.question = ''
        this.loading = true
        const res = await axios.post('/api/chat', {
            sid: session.sid,
            question,
        }, {
            headers: {
                token: user.token,
            },
        })
        this.loading = false
        if (!res) {
            Toast.show({
                content: '消息异常!',
            })
            return
        }
        const data: Assistant = res.data.data
        
        session.choices.push({
            role: 'assistant',
            content: data.content,
            index: data.index,
            limit: data.limit,
        })
    }
}

export const chat = new Chat()
