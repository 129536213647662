import { Toast } from 'antd-mobile'
import * as axios from '../utils/axios'
import { makeAutoObservable } from 'mobx'

class User {
    public token: string = ''

    public user: string = ''

    public inited: boolean = false

    constructor () {
        makeAutoObservable(this)
    }

    async init (): Promise<void> {
        this.token = localStorage.token
        if (!this.token) {
            this.inited = true
            return
        }
        const res = await axios.post('/api/check', {
            token: this.token,
        })
        if (!res) {
            this.inited = true
            return
        }
        if (!res.data.user) {
            this.token = ''
            localStorage.token = ''
        } else {
            this.user = res.data.user
        }
        this.inited = true
    }

    async login (): Promise<void> {
        if (!this.user) {
            Toast.show({
                content: '用户名为空!',
            })
            return
        }
        const res = await axios.post('/api/login', {
            user: this.user,
        })
        if (!res) {
            return
        }
        const token = res.data.token
        this.token = token
        localStorage.token = token
    }

    clear (): void {
        localStorage.token = null
        this.token = ''
    }

    input (user: string): void {
        this.user = user
    }
}

export const user = new User()
